
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'course-summary-report',
  components: {
    Datatable,
    Multiselect,
  },
  data() {
    return {
      entityType: [
        {
          id: 1009,
          label: 'Public',
        },
        {
          id: 1007,
          label: 'Private',
        },
        {
          id: 1010,
          label: 'Industry',
        },
      ],
      batch: {
        tranche_id: [] as any,
        start_date: '',
        end_date: '',
        entity_type_id: '',
      },
      api_url: '',

      TotalInstitute: 0 as any,
      Totalenrollment: 0 as any,
      Totalenrollment_female: 0 as any,
      Totalcertification: 0 as any,
      Totalcertification_female: 0 as any,
      Totaljobplacement: 0 as any,
      Totaljobplacement_female: 0 as any,
       Totalassesment: 0 as any,
      Totalassesment_female: 0 as any,
      data: [],
      tranches_info: [] as any,
      optionsTranche: [] as any,
      courseSummaryReport: [] as any,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      TotalCourse: 0 as any,
      TotalBatch: 0 as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type_id);
      let data = `${this.VUE_APP_API_URL}/api/report/course-summary_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type_id);
      await ApiService.post('report/course-summary_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_type', this.batch.entity_type_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/course-summary_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Enrollment Summary Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {

          this.tranches_info = response.data.data;
          this.tranches_info.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    trainingProviderNotice() {
      if (this.batch.tranche_id != '') {
        this.showCourseNotice = true;
        this.loading = true;
        this.load = true;
        ApiService.get(
          'report/course-summary?tranche_id=' +
          this.batch.tranche_id +
          '&entity_type=' +
          this.batch.entity_type_id
        )
          .then((response) => {
            this.loading = false;
            this.courseSummaryReport = response.data.data.coursedata;
            this.TotalInstitute = response.data.data.TotalInstitute;
            this.TotalCourse = response.data.data.TotalCourse;
            this.TotalBatch = response.data.data.TotalBatch;
            this.Totalenrollment = response.data.data.Totalenrollment;
            this.Totalenrollment_female =
              response.data.data.Totalenrollment_female;
            this.Totalcertification = response.data.data.Totalcertification;
            this.Totalcertification_female =
              response.data.data.Totalcertification_female;
            this.Totaljobplacement = response.data.data.Totaljobplacement;
            this.Totaljobplacement_female = response.data.data.Totaljobplacement_female;
            this.Totalassesment = response.data.data.Totalassesment;
              this.Totalassesment_female = response.data.data.Totalassesment_female;
            this.load = false;
            this.loading = false;
            console.log(response);
          })
          .catch((response) => {
            this.loading = false;
            this.load = false;

            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please Select Tranche!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.load = false;
        this.loading = false;
      }
    },
  },
  setup() { },
});
